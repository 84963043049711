
body {
    // disable double tapping zoom
    touch-action: manipulation;
    // prevent pull down happpening in the browser
    overscroll-behavior-y: contain;
}

#touch_controls {
    text-align: center;
}

#touch_controls > div {
    width: 48px;
    height: 48px;
    background-color: beige;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 8px;
    border: 1px solid gray;
    display: inline-block;
    user-select: none;
}

#touch_controls > div:nth-child(1) {
    background-image: data-uri('icons/arrow-up.svg');
}

#touch_controls > div:nth-child(2) {
    background-image: data-uri('icons/arrow-down.svg');
}

#touch_controls > div:nth-child(3) {
    background-image: data-uri('icons/arrow-left.svg');
}

#touch_controls > div:nth-child(4) {
    background-image: data-uri('icons/arrow-right.svg');
}

#touch_controls > div:nth-child(5) {
    background-image: data-uri('icons/flame.svg');
}


#touch_controls_v2 {

    padding: 0;
    margin: auto;
    display: block;
    width: 400px;
    height: 400px;
    background-color: black;

    border:5px solid #616161;
}
