body {
  touch-action: manipulation;
  overscroll-behavior-y: contain;
}

#touch_controls {
  text-align: center;
}

#touch_controls > div {
  width: 48px;
  height: 48px;
  -webkit-user-select: none;
  user-select: none;
  background-color: beige;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid gray;
  border-radius: 8px;
  display: inline-block;
}

#touch_controls > div:nth-child(1) {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20class%3D%22icon%20icon-tabler%20icon-tabler-arrow-up%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20stroke-width%3D%222%22%20stroke%3D%22currentColor%22%20fill%3D%22none%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%0A%20%20%20%20%3Cpath%20stroke%3D%22none%22%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%3E%3C%2Fpath%3E%0A%20%20%20%20%3Cline%20x1%3D%2212%22%20y1%3D%225%22%20x2%3D%2212%22%20y2%3D%2219%22%3E%3C%2Fline%3E%0A%20%20%20%20%3Cline%20x1%3D%2218%22%20y1%3D%2211%22%20x2%3D%2212%22%20y2%3D%225%22%3E%3C%2Fline%3E%0A%20%20%20%20%3Cline%20x1%3D%226%22%20y1%3D%2211%22%20x2%3D%2212%22%20y2%3D%225%22%3E%3C%2Fline%3E%0A%20%3C%2Fsvg%3E");
}

#touch_controls > div:nth-child(2) {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20class%3D%22icon%20icon-tabler%20icon-tabler-arrow-down%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20stroke-width%3D%222%22%20stroke%3D%22currentColor%22%20fill%3D%22none%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%0A%20%20%20%20%3Cpath%20stroke%3D%22none%22%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%3E%3C%2Fpath%3E%0A%20%20%20%20%3Cline%20x1%3D%2212%22%20y1%3D%225%22%20x2%3D%2212%22%20y2%3D%2219%22%3E%3C%2Fline%3E%0A%20%20%20%20%3Cline%20x1%3D%2218%22%20y1%3D%2213%22%20x2%3D%2212%22%20y2%3D%2219%22%3E%3C%2Fline%3E%0A%20%20%20%20%3Cline%20x1%3D%226%22%20y1%3D%2213%22%20x2%3D%2212%22%20y2%3D%2219%22%3E%3C%2Fline%3E%0A%20%3C%2Fsvg%3E");
}

#touch_controls > div:nth-child(3) {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20class%3D%22icon%20icon-tabler%20icon-tabler-arrow-left%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20stroke-width%3D%222%22%20stroke%3D%22currentColor%22%20fill%3D%22none%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%0A%20%20%20%20%3Cpath%20stroke%3D%22none%22%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%3E%3C%2Fpath%3E%0A%20%20%20%20%3Cline%20x1%3D%225%22%20y1%3D%2212%22%20x2%3D%2219%22%20y2%3D%2212%22%3E%3C%2Fline%3E%0A%20%20%20%20%3Cline%20x1%3D%225%22%20y1%3D%2212%22%20x2%3D%2211%22%20y2%3D%2218%22%3E%3C%2Fline%3E%0A%20%20%20%20%3Cline%20x1%3D%225%22%20y1%3D%2212%22%20x2%3D%2211%22%20y2%3D%226%22%3E%3C%2Fline%3E%0A%20%3C%2Fsvg%3E");
}

#touch_controls > div:nth-child(4) {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20class%3D%22icon%20icon-tabler%20icon-tabler-arrow-right%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20stroke-width%3D%222%22%20stroke%3D%22currentColor%22%20fill%3D%22none%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%0A%20%20%20%20%3Cpath%20stroke%3D%22none%22%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%3E%3C%2Fpath%3E%0A%20%20%20%20%3Cline%20x1%3D%225%22%20y1%3D%2212%22%20x2%3D%2219%22%20y2%3D%2212%22%3E%3C%2Fline%3E%0A%20%20%20%20%3Cline%20x1%3D%2213%22%20y1%3D%2218%22%20x2%3D%2219%22%20y2%3D%2212%22%3E%3C%2Fline%3E%0A%20%20%20%20%3Cline%20x1%3D%2213%22%20y1%3D%226%22%20x2%3D%2219%22%20y2%3D%2212%22%3E%3C%2Fline%3E%0A%20%3C%2Fsvg%3E");
}

#touch_controls > div:nth-child(5) {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20class%3D%22icon%20icon-tabler%20icon-tabler-flame%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20stroke-width%3D%222%22%20stroke%3D%22currentColor%22%20fill%3D%22none%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%0A%20%20%20%20%3Cpath%20stroke%3D%22none%22%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%3E%3C%2Fpath%3E%0A%20%20%20%20%3Cpath%20d%3D%22M12%2012c2%20-2.96%200%20-7%20-1%20-8c0%203.038%20-1.773%204.741%20-3%206c-1.226%201.26%20-2%203.24%20-2%205a6%206%200%201%200%2012%200c0%20-1.532%20-1.056%20-3.94%20-2%20-5c-1.786%203%20-2.791%203%20-4%202z%22%3E%3C%2Fpath%3E%0A%20%3C%2Fsvg%3E");
}

#touch_controls_v2 {
  width: 400px;
  height: 400px;
  background-color: #000;
  border: 5px solid #616161;
  margin: auto;
  padding: 0;
  display: block;
}

/*# sourceMappingURL=index.09690d7d.css.map */
